<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header>
      <p>Classes</p>
    </ion-list-header>

    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item
        class="hydrated link"
        :router-link="{ name: 'attendance.show', params: { class: item.id } }"
      >
        <ion-label>
          <h2>{{ item.standard.name }} - {{ item.section.name }}</h2>
          <p>{{ item.students_count }} students</p>
        </ion-label>
        <ion-note slot="end">
          {{ item.teacher.user.user_detail.name }}
        </ion-note>
        <!-- <ion-buttons slot="end">
          <ion-button @click="slide(item.id)">
            <ion-icon
              slot="icon-only"
              :ios="ellipsisHorizontalOutline"
              :md="ellipsisHorizontalSharp"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons> -->
      </ion-item>
      <!-- <ion-item-options side="start">
        <ion-item-option color="light" @click="setResourceToUpdate(item.id)">
          <ion-icon
            slot="icon-only"
            :ios="createOutline"
            :md="createSharp"
            size="small"
          ></ion-icon>
        </ion-item-option>
        <ion-item-option
          color="light"
          @click="
            confirmDelete({
              id: item.id,
              name: item.standard.name + ' - ' + item.section.name,
            })
          "
        >
          <ion-icon
            slot="icon-only"
            :ios="trashOutline"
            :md="trashSharp"
            size="small"
            style="color: #eb445a"
          ></ion-icon>
        </ion-item-option>
      </ion-item-options> -->
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No classes found</p>
    </ion-list-header>
  </ion-list>
  <!-- <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update class teacher"
      @closeModal="setUpdateModalState(false)"
    >
      <update-class
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-class>
    </base-modal>
  </ion-modal> -->
</template>
<script>
// import BaseModal from "../../../Base/BaseModal";
// import UpdateClass from "../Class/UpdateClass";

import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  // IonItemOptions,
  // IonItemOption,
  IonItem,
  IonNote,
  // IonButtons,
  // IonButton,
  // IonIcon,
  // IonModal,
  // alertController,
} from "@ionic/vue";

// import {
//   trashOutline,
//   trashSharp,
//   createOutline,
//   createSharp,
//   ellipsisHorizontalOutline,
//   ellipsisHorizontalSharp,
// } from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItemSliding,
    // IonItemOptions,
    // IonItemOption,
    IonItem,
    IonNote,
    // IonButtons,
    // IonButton,
    // IonIcon,
    // IonModal,
    // BaseModal,
    // UpdateClass,
  },
  data() {
    return {
      // trashOutline,
      // trashSharp,
      // createOutline,
      // createSharp,
      // ellipsisHorizontalOutline,
      // ellipsisHorizontalSharp,

      // updateModalState: false,

      // resourceToUpdate: {
      //   id: null,
      //   name: null,
      //   hierachy: null,
      // },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    // slide(id) {
    //   this.$refs[`items-${id}`].$el.open();
    // },
    // alerter(header, message, buttons = ["close"]) {
    //   const alert = alertController.create({
    //     header: header,
    //     message: message,
    //     buttons: buttons,
    //   });
    //   return alert;
    // },
    // setErrorResponse(error) {
    //   if (error.response) {
    //     this.xhrError.header = error.response.data.header;
    //     this.xhrError.message = error.response.data.message;
    //   } else if (error.request) {
    //     console.log(error.request);
    //     this.xhrError.header = error.message || error.request;
    //     this.xhrError.message = "Please check your connection and try again";
    //   } else {
    //     this.xhrError.header = error.message;
    //     this.xhrError.message = "Something went wrong. Try again later";
    //   }
    // },
    // async confirmDelete(resource) {
    //   const header = `Delete class ${resource.name} ?`;
    //   const message = "This action cannot be undone.";
    //   const buttons = [
    //     {
    //       text: "Cancel",
    //       role: "cancel",
    //       cssClass: "secondary",
    //     },
    //     {
    //       text: "Okay",
    //       handler: () => {
    //         this.delete(resource.id);
    //       },
    //     },
    //   ];
    //   const alertDialog = await this.alerter(header, message, buttons);
    //   alertDialog.present();
    // },
    // async delete(data) {
    //   try {
    //     await this.$store.dispatch("class/deleteResource", data);
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },
    // setUpdateModalState(state) {
    //   this.updateModalState = state;
    // },
    // setResourceToUpdate(id) {
    //   const resource = this.resources.data.find(
    //     (resource) => resource.id === id
    //   );
    //   this.resourceToUpdate = resource;
    //   this.setUpdateModalState(true);
    // },
  },
};
</script>